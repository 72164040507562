import { useEffect, useState } from 'react';
import axios from 'axios';

export interface Artwork {
    id: string;
    isActive: Boolean;
    title: string;
    year: number;
    materials?: string;
    price?: number;
    descriptionHtml?: string;
    size: {
        height: number;
        width: number;
        depth?: number;
    };
}

export interface KeyValuePair {
    name: string;
    value: string;
}

export interface ContactFormProps {
    contactName: string;
    fromEmailAddress: string;
    subject: string;
    message: string;
    otherValues: KeyValuePair[];
}

export interface AxiosResult {
    loaded: boolean;
    loading: boolean;
    error: any;
    refetch: () => void;
}

const api = '/media';
// 'http://seekingsoma-media-bucket.s3-website-us-east-1.amazonaws.com';
// 'http://www.seekingsomaart.com/media';

export const useAxiosGet = <TResult>(axiosArgs: any) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [state, setState] = useState<TResult | null>(null);
    const refetch = () => {
        setLoading(true);
        axios
            .get(axiosArgs)
            .then((response) => {
                setState(response.data);
                setLoading(false);
            })
            .catch((err: any) => {
                setError(err);
            });
    };
    useEffect(refetch, [axiosArgs]);
    return [state, { error, loading, refetch, loaded: !loading }] as [
        TResult,
        AxiosResult
    ];
};

export const useArtwork = (id: string) => {
    const [artworks, result] = useArtworkList();
    return [artworks?.find((f) => f.id === id), result];
};

export const useArtworkList = (skip = 0, take = 25) => {
    return useAxiosGet<Artwork[]>(`${api}/artwork.json`);
};

// // export const useContact = () => {
// //     return client.contact.useMutation();
// // };
