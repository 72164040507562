/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import Layout from '../components/Layout';
import styled from '@emotion/styled';
import { css } from '@emotion/css';
import { Button, Dialog } from '@mui/material';

interface ImageProps {
    src: string;
    alt: string;
    setFullsizeProps: (props: ImageProps) => void;
}

const Thumbnail = (props: ImageProps) => {
    return (
        <img
            onClick={() => {
                props.setFullsizeProps(props);
            }}
            className={css`
                max-width: 200px;
                max-height: 200px;
                cursor: pointer;
            `}
            {...props}
        />
    );
};

const Fullsize = (props: Omit<ImageProps, 'setFullsizeProps'>) => {
    return (
        <img
            className={css`
                max-width: 100%;
                max-height: 100%;
                background-color: black;
            `}
            {...props}
        />
    );
};
styled.img`
    max-width: 800px;
    max-height: 800px;
    margin: 1rem;
`;

export const Design = () => {
    const [isOpen, setIsOpen] = React.useState(false);
    const [fullsizeProps, setFullsizeProps] = React.useState({} as ImageProps);
    const handleOpen = (props: ImageProps) => {
        setIsOpen(true);
        setFullsizeProps(props);
    };
    return (
        <Layout>
            <div>
                <h1>Design</h1>
                <p>
                    While I dedicate myself to painting first and foremost,
                    design plays a large role in my work as I often make logos
                    and stickers for each piece and create all my own marketing
                    materials. In my day job, I work as a software engineer and
                    have designed several websites and applications, including
                    this one.
                </p>
                <div>
                    <h2>Logos</h2>
                    <div>
                        <Thumbnail
                            src="/images/design/zig-zag-zen.png"
                            alt="Zig Zag Zen Logo"
                            setFullsizeProps={handleOpen}
                        />
                        <Thumbnail
                            src="/images/design/mystery-babylon.png"
                            alt="Mystery Babylon Logo"
                            setFullsizeProps={handleOpen}
                        />
                        <Thumbnail
                            src="/images/design/blazing-june-logo.png"
                            alt="Blazing June Logo"
                            setFullsizeProps={handleOpen}
                        />
                    </div>
                </div>
                <div>
                    <h2>Stickers</h2>
                    <Thumbnail
                        src="/images/stickers.jpeg"
                        alt="Bicycle Day Stickers"
                        setFullsizeProps={handleOpen}
                    />
                </div>
            </div>
            <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
                <Fullsize {...fullsizeProps} />
                <Button onClick={() => setIsOpen(false)}>Close</Button>
            </Dialog>
        </Layout>
    );
};

export default Design;
