/* eslint-disable react-hooks/exhaustive-deps */
import { Route, Link, useParams } from 'react-router-dom';
import { Layout, Footer } from '../../components/Layout';

import { Navigation } from '../../components/navigation';
import styles from './index.module.scss';
import {
    FacebookShareButton,
    FacebookIcon,
    RedditShareButton,
    RedditIcon,
    PinterestShareButton,
    PinterestIcon,
    TwitterShareButton,
    TwitterIcon,
} from 'react-share';
import {
    MediaImageFit,
    MediaImage,
    useMediaImageUrl,
    SizesEnum,
} from '../../components/MediaImage';
import MediaQuery from 'react-responsive';
import { Artwork, useArtwork } from '../../client';
import styled from '@emotion/styled';

// $primaryColor : #fca903;
// $serifFont : 'Cinzel', serif;
// $sansSerifFont : 'Montserrat', sans-serif;

const ArtworkTitle = styled.div`
    color: #fca903;
    font-family: 'Cinzel', serif;
    font-size: 1.5em;
    box-sizing: border-box;
`;

const ArtworkInfo = styled.div`
    display: inline;
`;

const FooterContainer = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-shrink: 1;
    padding: 20px;
    width: 100%;
    max-width: 720px;
    margin: auto;
    padding: 20px 30px;
`;

export const Share = ({ artwork }: { artwork: Artwork }) => {
    const shareUrl = document.location.href;
    const mediaUrl = useMediaImageUrl({
        artwork,
        fit: MediaImageFit.COVER,
    });
    const size = '30px';
    return (
        <div
            style={{
                height: '30px',
            }}
        >
            <FacebookShareButton url={shareUrl}>
                <FacebookIcon size={size} />
            </FacebookShareButton>
            <RedditShareButton url={shareUrl}>
                <RedditIcon size={size} />
            </RedditShareButton>
            <PinterestShareButton url={shareUrl} media={mediaUrl}>
                <PinterestIcon size={size} />
            </PinterestShareButton>
            <TwitterShareButton url={shareUrl}>
                <TwitterIcon size={size} />
            </TwitterShareButton>
        </div>
    );
};

export const ArtworkFooter = (props: {
    className?: string;
    artwork: Artwork;
    displayImageIndex: string | number;
}) => {
    const { artwork, className } = props;
    if (!artwork) {
        return null;
    }

    return (
        <FooterContainer>
            <div>
                <ArtworkTitle>{artwork?.title}</ArtworkTitle>
                <ArtworkInfo>
                    {artwork?.materials} - {artwork?.year}
                </ArtworkInfo>
            </div>
            <Share artwork={artwork} />
        </FooterContainer>
    );
};

export const LightboxSection = ({
    artwork,
    displayImageIndex,
    imageSize,
    className,
}: {
    artwork: Artwork;
    displayImageIndex: string | number;
    imageSize: SizesEnum;
    fit: MediaImageFit;
    className?: string;
}) => {
    return (
        <div style={{ flexGrow: 5 }}>
            <MediaImage
                artwork={artwork}
                className={styles.image}
                size={imageSize}
                fit={MediaImageFit.FILL}
                style={{ height: '100%', width: '100%;' }}
            ></MediaImage>
        </div>
    );
};
export const ArtworkPageContainer = styled.div`
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
`;

export const ArtworkPage = ({
    id,
    displayImageIndex,
    artwork,
}: ArtworkPageProps) => {
    return (
        <ArtworkPageContainer>
            <Navigation />
            <LightboxSection
                artwork={artwork}
                className={styles.mobileImage}
                displayImageIndex={displayImageIndex}
                fit={MediaImageFit.COVER}
                imageSize={SizesEnum.Large}
            />
            <ArtworkFooter
                className={styles.mobileContent}
                artwork={artwork}
                displayImageIndex={displayImageIndex}
            />
        </ArtworkPageContainer>
    );
};

export interface ArtworkPageProps {
    artwork?: Artwork;
    id: string;
    displayImageIndex: string;
}

export const ArtworkRouter = () => {
    const { id = null } = useParams() as {
        id: string;
        displayImageIndex: string;
    };
    const [artwork] = useArtwork(id);
    const pageProps = {
        artwork,
        id,
    } as ArtworkPageProps;
    if (!artwork) {
        return null;
    }
    return <ArtworkPage {...pageProps} />;
};

export default ArtworkRouter;
