import orderBy from 'lodash/orderBy';
import Layout from '../../components/Layout';
import styles from './index.module.scss';

export enum ExhibitionType {
    solo = 'Solo Exhibition',
    group = 'Group Exhibition',
    live = 'Live Painting',
}

export interface CvEntry {
    name: string;
    year: number;
    city: string;
    type: 'solo' | 'group' | 'live';
}
export const cv = [
    { name: 'Cafe Lulu', year: 2021, city: 'Seattle, WA', type: 'solo' },
    { name: 'Retail Therapy', year: 2022, city: 'Seattle, WA', type: 'solo' },
    { name: 'SEAF 2022', year: 2022, city: 'Seattle, WA', type: 'group' },
    { name: 'SEAF 2023', year: 2023, city: 'Seattle, WA', type: 'group' },
    {
        name: 'Seattle Art Post group show',
        year: 2021,
        city: 'Kirkland, WA',
        type: 'group',
    },
    {
        name: 'Seacompression 2019',
        year: 2019,
        city: 'Seattle, WA',
        type: 'live',
    },
    {
        name: 'Seacompression 2022',
        year: 2022,
        city: 'Seattle, WA',
        type: 'live',
    },
    {
        name: 'Seacompression 2023',
        year: 2023,
        city: 'Seattle, WA',
        type: 'group',
    },
] as CvEntry[];
export const cvData = orderBy(cv, ['year', 'name'], 'desc');

export const About = () => {
    return (
        <Layout>
            <div className={styles.about}>
                <h1>About</h1>
                <div>
                    <p>
                        My name is Miguel Eduardo. I consider myself a
                        conceptual, pop-surrealist and visionary artist. My work
                        is centered around non-ordinary experiences; around the
                        erotic, mystical, mythical, and psychedelic.
                    </p>
                    <p>
                        I am a versatile artist, as comfortable with oils as
                        with clay, metal, plastics, spray paint and digital
                        media. In this age of easy reproduction, I focus on
                        creating work in physical media that is best appreciated
                        face to face. I want the act of being present in front
                        of the work to be an act of exploration and discovery,
                        often adding matetial details that cannot be conveyed
                        through image alone. The rendered visual style causally
                        dances between traditional representation and heavy
                        graphics.
                    </p>
                </div>
                <div>
                    <h3>What is Seeking Soma?</h3>
                    <p>
                        Seeking Soma is a mission and values statement for me.
                    </p>
                    <p>
                        "Seeking" represents subjectivity, the internal journey,
                        the reflection, observation and analysis of experience.
                    </p>
                    <p>
                        "Soma" expresses a sort of divine physicality; an idea
                        that serves well for someone creating art. Soma was the
                        name of a Vedic mushroom god, the drug from Brave New
                        World and a term for embodiment and wholeness.
                    </p>
                    <p>
                        Seeking Soma is my quest to find embodiment,
                        manifestation, divinity, the liminal and psychedelic.
                        The phrase expresses the interplay of duality, of mind
                        and body, each arising from the other.
                    </p>
                    <p>
                        On a practical level, Seeking Soma Art is a place for me
                        to have full creative control and express all aspects of
                        my exploration in the arts and of the mind. It's where I
                        get to enact my broader vision of bringing beauty to the
                        world.
                    </p>
                </div>
                <div>
                    <h3>Education</h3>
                    <p>Virginia Commonwealth University, BFA Sculpture, 2005</p>
                </div>
                <div>
                    <h3>CV</h3>
                    <ul>
                        {cvData.map(({ name, city, type, year }) => {
                            return (
                                <li>
                                    {year} - {name} ({ExhibitionType[type]} -{' '}
                                    {city})
                                </li>
                            );
                        })}
                    </ul>
                </div>
            </div>
        </Layout>
    );
};

export default About;
