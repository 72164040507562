/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import Layout from '../../components/Layout';
import { Artwork } from '../../client';
import { groupBy } from 'ramda';
import styles from './index.module.scss';
import { Link } from 'react-router-dom';
import MediaImage, {
    MediaImageFit,
    SizesEnum,
} from '../../components/MediaImage';
import { useArtworkList } from '../../client';
import { orderBy } from 'lodash';

export const Thumbnail = ({ artwork }: { artwork: Artwork }) => {
    return (
        <Link to={`/${artwork.id}`} className={styles.thumbnail}>
            <MediaImage
                artwork={artwork}
                fit={MediaImageFit.COVER}
                size={SizesEnum.Medium}
                width={500}
                height={500}
                jpgQuality={50}
                className={styles.mediaImage}
            ></MediaImage>
            <h3 className={styles.title}>{artwork.title}</h3>
        </Link>
    );
};

export const ArtworkGroup = ({
    data,
    name,
}: {
    data: Artwork[];
    name: String;
}) => {
    return (
        <div>
            <div className={styles.artworkGroupContainer}>
                {orderBy(data, ['year'], ['desc']).map((artwork) => (
                    <Thumbnail artwork={artwork} />
                ))}
            </div>
        </div>
    );
};

export const Home = () => {
    const [artworks, { loaded }] = useArtworkList();
    let children: JSX.Element[] = [];

    if (loaded && artworks) {
        const groupedArtworks = Object.entries(
            groupBy((artwork) => artwork.series, artworks)
        );
        children = groupedArtworks.map((group) => {
            const name = group[0] as String;
            const data = group[1] as Artwork[];
            return <ArtworkGroup name={name} data={data} />;
        });
    }

    return <Layout>{children}</Layout>;
};

export default Home;
