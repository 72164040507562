import React from 'react';
import { Artwork } from '../../client';

export const SLASHES_REGEX = /\/*$/g;
export const SEARCH_REGEX = /host=(.*?)(&|$)/;

export enum SizesEnum {
    XSmall = 'xs', // 150
    Small = 'sm', // 300
    Medium = 'md', // 600,
    Large = 'lg', // 900,
    XLarge = 'xl', // 1200,
}

export enum MediaImageFormat {
    JPEG = 'jpeg', // default
    PNG = 'png',
}

export enum MediaImageFit {
    COVER = 'cover', // default
    CONTAIN = 'contain',
    FILL = 'fill',
    INSIDE = 'inside',
    OUTSIDE = 'outside',
}

export interface MediaImageProps {
    artwork?: Artwork;
    size?: SizesEnum;
    artworkId?: string;
    mediaId?: string;
    index?: number;
    jpgQuality?: number;
    height?: number;
    width?: number;
    fit?: MediaImageFit;
    type?: MediaImageFormat;
}

export interface MediaImageComponentProps extends MediaImageProps {
    className?: string;
    style?: Object;
}

export const fieldOrder = [
    'mediaId',
    'artworkId',
    'index',
    'height',
    'width',
    'fit',
    'format',
    'jpgQuality',
];

export const fieldDefaults = {
    index: 0,
    imageHeight: 750,
    imageWidth: 750,
    jpgQuality: 80,
    fit: MediaImageFit.COVER,
    type: MediaImageFormat.JPEG,
} as Partial<MediaImageProps>;

export const useMediaImageUrl = (mediaImageProps: Partial<MediaImageProps>) => {
    return `/media/${mediaImageProps.artwork.id}-${mediaImageProps.size}`;
    // return `http://seekingsoma-media-bucket.s3-website-us-east-1.amazonaws.com/${mediaImageProps.artwork.id}-${mediaImageProps.size}`;
    //return `http://localhost:3003/media-bucket/${mediaImageProps.artwork.id}-${mediaImageProps.size}`;
};

export const MediaImage = (props: MediaImageComponentProps) => {
    const { style, className, fit } = props;
    console.info(props);
    const mediaImageUrl = useMediaImageUrl(props);
    const divStyle = {
        ...style,
        backgroundImage: `url('${mediaImageUrl}')`,
        backgroundSize: fit === MediaImageFit.COVER ? 'cover' : 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
    };
    return <div className={className} style={divStyle}></div>;
};

export default MediaImage;
