import React, { Link } from 'react-router-dom';
import classNames from 'classnames';

export const LogoImage = ({ style }: { style?: Record<string, string> }) => {
    return (
        <img
            style={style}
            src="/images/logo.png"
            alt="Seeking Soma Art - The artwork of Miguel Eduardo"
        />
    );
};

export const Logo = ({
    className,
    style,
}: {
    className?: string;
    style?: Record<string, string>;
}) => {
    return (
        <Link
            to="/"
            className={classNames(className)}
            style={style}
            title="Seeking Soma Art - The artwork of Miguel Eduardo"
        >
            <LogoImage
                style={{
                    height: '100%',
                }}
            />
        </Link>
    );
};
export default Logo;
