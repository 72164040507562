import MediaQuery from 'react-responsive';
import { Logo } from './Logo';
import { Link } from 'react-router-dom';

export const NavList = ({ style }: { style: Record<string, string> }) => {
    return (
        <ul style={style}>
            <li>
                <Link to="/">Home</Link>
            </li>
            <li>
                <Link to="/about">About</Link>
            </li>
            <li>
                <Link to="/contact">Contact</Link>
            </li>
        </ul>
    );
};

export const Navigation = () => {
    return (
        <>
            <MediaQuery maxWidth="720px">
                <div>
                    <Logo
                        style={{
                            height: '70px',
                            display: 'block',
                            margin: 'auto',
                        }}
                    />
                    <MediaQuery minWidth="420px">
                        <NavList
                            style={{
                                listStyle: 'none',
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                width: '300px',
                                margin: 'auto',
                                paddingBottom: '10px',
                                fontSize: '1.1em',
                            }}
                        />
                    </MediaQuery>
                </div>
            </MediaQuery>
            <MediaQuery minWidth="721px">
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        textAlign: 'center',
                    }}
                >
                    <Logo
                        style={{
                            height: '90px',
                            display: 'block',
                            margin: 'auto',
                        }}
                    />
                    <NavList
                        style={{
                            listStyle: 'none',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            width: '500px',
                            margin: 'auto',
                            paddingBottom: '10px',
                            fontSize: '1.3em',
                        }}
                    />
                </div>
            </MediaQuery>
        </>
    );
};
