/* eslint-disable react-hooks/exhaustive-deps */
import { Link } from 'react-router-dom';
import Layout from '../../components/Layout';

export const Contact = () => {
    return (
        <Layout>
            <div>
                <h1>Contact</h1>
                <div>
                    <p>
                        <center>
                            I am available for commissions for{' '}
                            <Link to="/design">design</Link>, portraits,
                            paintings, murals.
                        </center>
                    </p>
                    <p>
                        <center>
                            <a href="mailto:seeker@seekingsoma.com">
                                seeker@seekingsoma.com
                            </a>
                        </center>
                    </p>
                </div>
            </div>
        </Layout>
    );
};

export default Contact;
